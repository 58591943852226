(function($){
	$.fn.popup = function (o) {
		if (o === 'show') {
			this.fadeIn();
			$(window).resize();
		}

		if (o === 'hide') {
			this.fadeOut();
		}

		o = $.extend({
			"opener": ".call-back a",
			"popup_holder": "#call-popup",
			"popup": ".popup",
			"close_btn": ".btn-close",
			"close": function () { },
			"beforeOpen": function (popup) {
				$(popup).css({
					'left': 0,
					'top': 0
				}).hide();
			}
		}, o);

		return this.each(function () {
			var container = $(this),
			opener = $(o.opener, container),
			popup_holder = $(o.popup_holder, container),
			popup = $(o.popup, popup_holder),
			close = $(o.close_btn, popup),
			bg = $('.bg', popup_holder);
			popup.css('margin', 0);
			opener.click(function (e) {
				o.beforeOpen.apply(this, [popup_holder]);
				popup_holder.css('left', 0);
				popup_holder.fadeIn(350);
				alignPopup();
				bgResize();
				e.preventDefault();
				$(window).resize();
			});

			function alignPopup() {
				var deviceAgent = navigator.userAgent.toLowerCase();
				var agentID = deviceAgent.match(/(iphone|ipod|ipad|android)/i);
				if (agentID) {
					if (popup.outerHeight() > window.innerHeight) {
						popup.css({ 'top': $(window).scrollTop(), 'left': ((window.innerWidth - popup.outerWidth()) / 2) + $(window).scrollLeft() });
						return false;
					}
					popup.css({
						'top': ((window.innerHeight - popup.outerHeight()) / 2) + $(window).scrollTop(),
						'left': ((window.innerWidth - popup.outerWidth()) / 2) + $(window).scrollLeft()
					});
				} else {
					if (popup.outerHeight() > $(window).outerHeight()) {
						popup.css({ 'top': $(window).scrollTop(), 'left': (($(window).width() - popup.outerWidth()) / 2) + $(window).scrollLeft() });
						return false;
					}
					popup.css({
						'top': (($(window).height() - popup.outerHeight()) / 2) + $(window).scrollTop(),
						'left': (($(window).width() - popup.outerWidth()) / 2) + $(window).scrollLeft()
					});
				}
			}

			function bgResize() {
				var _w = $(window).width(),
				_h = $(document).height();
				bg.css({ "height": _h, "width": _w + $(window).scrollLeft() });
			}

			$(window).resize(function () {
				if (popup_holder.is(":visible")) {
					bgResize();
					alignPopup();
				}
			});

			if (popup_holder.is(":visible")) {
				bgResize();
				alignPopup();
			}

			close.add(bg).click(function (e) {
				var closeEl = this;
				popup_holder.fadeOut(350, function () {
					o.close.apply(closeEl, [popup_holder]);
				});
				e.preventDefault();
			});
			
			$('body').keydown(function (e) {
				if (e.keyCode === '27') {
					popup_holder.fadeOut(350);
				}
			});
		});
	};
})(jQuery);

