(function ($) {
	var ASPIRE = {
		common: {
			init: function () {
				ASPIRE.fixedHeader.init();
				ASPIRE.loadAnimation.init();
				ASPIRE.burgerMenu.init();
				ASPIRE.preloader.init();
				ASPIRE.accordion.init();
				ASPIRE.introSlider.init();
				ASPIRE.reachSlider.init();
				ASPIRE.aboutSlider.init();
				ASPIRE.reviews.init();
				ASPIRE.infoListExpanded.init();
				ASPIRE.teamExpanded.init();
				ASPIRE.customSelect.init();
				ASPIRE.modal.init();
				ASPIRE.share.init();
				ASPIRE.relatedList.init();
				ASPIRE.contentImgSlider.init();
				ASPIRE.accordionList.init();
				ASPIRE.ajax.init();
			}
		},

		accordionList: {
			init: function () {
				$('.default-list .plus-ico').on('click', function (e) {
					e.preventDefault();
					$(this)
						.closest('li')
						.toggleClass('open')
						.find('.expanded')
						.stop()
						.slideToggle();
				});
			}
		},

		contentImgSlider: {
			init: function () {
				$('.img-conent-slider').slick({
					arrows: false,
					dots: true
				});
			}
		},

		relatedList: {
			init: function () {
				$('.articles-list').slick({
					slidesToShow: 3,
					arrows: false,
					dots: true,
					responsive: [
						{
							breakpoint: 860,
							settings: {
								slidesToShow: 2
							}
						},
						{
							breakpoint: 600,
							settings: {
								slidesToShow: 1
							}
						}
					]
				});
			}
		},

		share: {
			init: function () {
				$('.share-holder .title').on('click', function () {
					$(this).closest('.share-holder').toggleClass('open');
				});
			}
		},

		modal: {
			init: function () {
				$('[data-modal-open]').on('click', function () {
					$('.modal').each(function () {
						if ($(this).is(':visible')) {
							$(this).find('.close-modal').click();
						}
					});
				});

				$('[data-modal-close]').on('click', function () {
					if ($(this).data('modal-close').length > 0) {
						var modal = $(this).data('modal-close');
						$(modal).find('.close-modal').click();
					} else {
						$(this).closest('.modal').find('.close-modal').click();
					}
				});

				$('body')
					.popup({
						"opener": "[data-modal-open='#property-tool']",
						"popup_holder": "#property-tool",
						"popup": ".modal-content",
						"close_btn": ".close-modal"
					})
					.popup({
						"opener": "[data-modal-open='#property-tool-finish']",
						"popup_holder": "#property-tool-finish",
						"popup": ".modal-content",
						"close_btn": ".close-modal"
					});
			}
		},

		customSelect: {
			init: function () {
				$('select').each(function () {
					var $this              = $(this),
						selectOption       = $this.find('option'),
						selectOptionLength = selectOption.length,
						selectedOption     = selectOption.filter(':selected').text(),
						dur                = 350;

					$this.hide();
					$this.wrap('<div class="select"></div>');

					$('<div>', {
						class: 'select__gap',
						text: selectedOption
					}).insertAfter($this);

					var selectGap = $this.next('.select__gap'),
						caret     = selectGap.find('.caret');


					// Add ul list
					$('<ul>', {
						class: 'select__list'
					}).insertAfter(selectGap);

					var selectList = selectGap.next('.select__list');

					// Add li - option items
					for (var i = 0; i < selectOptionLength; i++) {
						if (!selectOption.eq(i).is(':disabled')) {
							$('<li>', {
								class: 'select__item',
								html: $('<span>', {
									text: selectOption.eq(i).text()
								})
							})
							.attr('data-value', selectOption.eq(i).val())
							.appendTo(selectList);
						}
					}

					// Find all items
					var selectItem = selectList.find('li');

					selectList.slideUp(0);

					selectGap.on('click', function () {
						var thisSelect = this;

						$('.select__gap.on').each(function () {
							if (this !== thisSelect) {
								$(this).removeClass('on');
								$(this).closest('.select').find('.select__list').slideUp(dur);
							}
						});

						if (!$(this).hasClass('on')) {
							$(this).addClass('on');
							selectList.slideDown(dur);

							selectItem.on('click', function () {
								var chooseItem = $(this).data('value');

								$(this).closest('.select').find('select').val(chooseItem).attr('selected', 'selected').change();
								selectGap.text($(this).find('span').text());

								selectList.slideUp(dur);
								selectGap.removeClass('on');
							});
						} else {
							$(this).removeClass('on');
							selectList.slideUp(dur);
						}
					});
				});

				$(document).on('click', function (e) {
					if ($('.select__gap.on').length && !$(e.target).closest('.select').length) {
						$('.select__gap.on').click();
					}
				});
			}
		},

		teamExpanded: {
			init: function () {
				$('.team-list .more').on('click', function (e) {
					e.preventDefault();
					$(this).closest('li').toggleClass('active');
					if ($(this).closest('li').hasClass('active')) {
						$(this).closest('li').find('.expanded').stop().slideDown(350);
						$(this)
							.closest('li')
							.siblings('li.active')
							.removeClass('active')
							.find('.expanded')
							.stop()
							.slideUp(350);
					} else {
						$(this).closest('li').find('.expanded').stop().slideUp(350);
					}
				});
			},
		},

		infoListExpanded: {
			init: function () {
				$('.info-list .inner').on('click', function () {
					if ($(window).innerWidth() < 768) {
						$(this).closest('li').toggleClass('active');
						if ($(this).closest('li').hasClass('active')) {
							$(this).siblings('.expanded-content').stop().slideDown(350);
							$(this)
								.closest('li')
								.siblings('li.active')
								.removeClass('active')
								.find('.expanded-content')
								.stop()
								.slideUp();
						} else {
							$(this).siblings('.expanded-content').stop().slideUp(350);
						}
					}
				});
				$('.info-list .ico').on('click mouseenter', function () {
					$(this)
						.closest('li')
						.addClass('hover')
						.siblings('li.hover')
						.removeClass('hover');
				});
				var timer;
				$('.info-list > li').on('mouseleave', function () {
					var self = this;
					timer = setTimeout(function () {
						$(self).removeClass('hover');
					}, 100);
				});
				$('.info-list .expanded-content').on('mouseenter', function () {
					clearTimeout(timer);
					$(this)
						.closest('li')
						.addClass('hover')
						.siblings('li.hover')
						.removeClass('hover');
				});
			},
		},

		loadAnimation: {
			init: function () {
				var wow = new WOW(
					{
						boxClass: 'wow',
						animateClass: 'animated',
						offset: 0,
						mobile: true,
						live: true,
						callback: function (box) {
						},
						scrollContainer: null
					}
				);

				wow.init();

				var s = skrollr.init();
				if ($('html').hasClass('skrollr-mobile')) {
					s.destroy();
				}

				$(window).on('load', function () {
					s.refresh();
				});
			}
		},

		burgerMenu: {
			init: function () {
				var menu = $('.navbar-collapse');
				var html = $("html");
				$('.nav-opener').click(function (e) {
					e.preventDefault();
					html.toggleClass("navbar-opened");
				});
				$('.close-nav, .nav-bg').click(function (e) {
					e.preventDefault();
					html.removeClass("navbar-opened");
				});
			},
		},
		
		reviews: {
			init: function () {
				$('.review-slider').slick({
					dots: true,
					arrows: false,
					centerMode: true,
					centerPadding: '0',
					variableWidth: true,
					focusOnSelect: true,
					responsive: [
						{
							breakpoint: 768,
							settings: {
								centerMode: false,
								variableWidth: false  
							}
						}
					]
				});
			}, 
		},

		introSlider: {
			init: function () {
				$('.intro-slider').slick({
					arrows: false,
					fade: true,
					dots: true,
					slide: '.item',
					autoplay: true, 
					autoplaySpeed: 6000,
					pauseOnHover: false,
					draggable: false
				});
			},
		},

		reachSlider: {
			init: function () {
				$slider = $('.reach-slider .container');

				$slider.slick({
					arrows: false,
					fade: true,
					dots: false,
					slide: '.item',
					// autoplay: true, 
					autoplaySpeed: 6000,
					draggable: false,
					adaptiveHeight: true
				}); 

				$('.reach-slider .slick-arrow').on('click', function () {
					if ($(this).hasClass('slick-prev')) {
						$slider.slick('slickPrev');
					} else {
						$slider.slick('slickNext');
					}
				});
			}
		},

		aboutSlider: {
			init: function () {
				$('.about-slider').slick({
					fade: true,
					arrows: false,
					autoplay: true, 
					autoplaySpeed: 6000,
					responsive: [
						{
							breakpoint: 768,
							settings: {
								fade: false
							}
						}
					]
				});
				$('.about-slider .slick-arrow').on('click', function () {
					if ($(this).hasClass('slick-prev')) {
						$(this).closest('.about-slider').slick('slickPrev');
					} else {
						$(this).closest('.about-slider').slick('slickNext');
					}
				});
			},
		},

		fixedHeader: {
			init: function () {
				var header = $('.header');
				var headerOffset = header.offset().top;

				ASPIRE.fixedHeader.set_header_state(header, headerOffset);
				
				$(window).scroll(function () {
					ASPIRE.fixedHeader.set_header_state(header, headerOffset);
				});
			},
			set_header_state: function(header, headerOffset) {
				var headerHeight = header.innerHeight();
				var scroll = $(window).scrollTop();
				// var scroll = $(window).scrollTop() - (headerHeight - 75);

				if (scroll > headerOffset + (headerHeight / 2) ) {
					header.addClass('fixed-header');

				} else {
					header.removeClass('fixed-header');
				}

			}
		},

		preloader: {
			init: function () {
				$('body').addClass('loader-hidden');
			}
		},

		accordion: {
			init: function () {
				$('.nav-list .menu-item-has-children > a').on('click', function (e) {
					e.preventDefault();
					$(this).closest('.menu-item-has-children').toggleClass('drop-open');
					if ($(this).closest('.menu-item-has-children').hasClass('drop-open')) {
						$(this).siblings('.sub-menu').stop().slideDown(350);
					} else {
						$(this).siblings('.sub-menu').stop().slideUp(350);
					}
				});
			}
		},

		ajax: {
			init: function(){
				if(typeof AjaxController !== "undefined"){
					window.ajaxcontroller = {
						news: new AjaxController('.archive-post', [
							{
								breakpoint: 0,
								settings: {
									submitOnChange: true,
									onSubmit: function(data){
										delete data.tag_id;
									},
									onClear: function(data){
										delete data.tag_id;
									},
									onChange: function(data){
										delete data.tag_id;
									},
									onPaginate: function(data){
									},
									onComplete: function(){
										ASPIRE.customSelect.init();
									}
								}
							}
						])
					};
				}
			}
		},

		contact: {
			init: function(){
				document.addEventListener( 'wpcf7mailsent', function( event ) {
					var $form_wrap = $(event.target),
						$form      = $form_wrap.find('form'),
						form_name  = $form.attr('name');
				
					if(typeof gtag !== 'undefined') {
						gtag('event', 'send', {
							'event_category': 'contact_form',
							'event_label': form_name,
							'event_action': 'submit',
						});
					}
				}, false );
			}
		}
	};

	var UTIL = {
		fire: function (func, funcname, args) {
			var namespace = ASPIRE;
			funcname = (funcname === undefined) ? 'init' : funcname;
			if (func !== '' && namespace[func] && typeof namespace[func][funcname] === 'function') {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			UTIL.fire('common');

			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
			});
		}
	};

	$(document).ready(UTIL.loadEvents);

})(jQuery);